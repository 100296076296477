import React from "react";
import "./Content.css";
import Header from "./Header";
import Footer from "./Footer";
import About from "../1.about/About";
import Services from "../2.services/Services";
import Experiences from "../3.experiences/Experiences";
import Competences from "../4.competences/Competences";
//import Realisations from "../5.realisations/Realisations";
//import Blog from '../6.blog/Blog'
import Contact from "../7.contact/Contact";

function Content() {
	return (
		<div id="content">
			{/* HEADER */}
			<Header id="header" />
			{/* SECTIONS */}
			<About id="about" />
			<Services />
			<Experiences />
			<Competences />
			{/*<Realisations />*/}
			{/*<Blog />*/}
			<Contact />
			{/* FOOTER */}
			<Footer />
		</div>
	);
}

export default Content;
