import React from "react";
import { AiOutlineLink, AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import "./Contact.css";

const Contact = () => {
	return (
		<section className="content_section" id="contact">
			<div className="content_section_inner">
				<h2>Contactez-moi</h2>
				<div className="container">
					<div className="col col-1-3 contact-infos">
						<div className="contact-cta">
							<h4 style={{ marginBottom: "10px" }}>
								J'aimerais vous entendre!
							</h4>
							<p>
								Si vous souhaitez un devis ou plus
								d'informations pour travailler avec moi,
								veuillez remplir le formulaire avec une
								description détaillée de votre projet, et je
								vous répondrai dans les plus brefs délais!
							</p>
						</div>
						<div className="contact-links">
							<div className="contact-links-item">
								<div className="icon">
									<AiOutlineMail />
								</div>
								<p>
									Ecrivez-moi
									<a href="mailto:info@enovatic.be">
										info@enovatic.be
									</a>
								</p>
							</div>
							<div className="contact-links-item">
								<div className="icon">
									<FiPhoneCall />
								</div>
								<p>
									Appelez-moi
									<a href="tel:+320484295694">
										+32 (0) 484 29 56 94
									</a>
								</p>
							</div>
							<div className="contact-links-item">
								<div className="icon">
									<AiOutlineLink />
								</div>
								<p>
									Mon site
									<a
										href="https://enovatic.be" 
										target="_blank"
										rel="noreferrer"  
									>
										Enovatic.be
									</a>
								</p>
							</div>
						</div>
					</div>
					<div className="col col-2-3 contact-form">
						<div className="container-form-grid">
							<input
								name="contact-lastname"
								type="text"
								placeholder="Entrez votre nom"
								className="contact-lastname col"
							/>
							<input
								name="contact-firstname"
								type="text"
								placeholder="Entrez votre prénom"
								className="contact-firstname col"
							/>
							<input
								name="contact-phone"
								type="text"
								placeholder="Entrez votre téléphone"
								className="contact-phone col"
							/>
							<input
								name="contact-email"
								type="email"
								placeholder="Entrez votre email"
								className="contact-email col"
							/>
							<input
								name="contact-subject"
								type="text"
								placeholder="Sujet"
								className="contact-subject col col-full"
							/>
							<textarea
								name="contact-message"
								className="contact-message col col-full"
								placeholder="Décrivez votre demande ici"
								cols="30"
								rows="6"
							></textarea>
							<input
								type="submit"
								name="contact-submit"
								className="contact-submit col"
								value="Envoyer le message"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
