import React from 'react'
import './Footer.css'
import meta from '../../assets/img/social-meta.svg'
import dribble from '../../assets/img/social-dribble.svg'
import pinterest from '../../assets/img/social-pinterest.svg'
import github from '../../assets/img/social-github.svg'
import twitter from '../../assets/img/social-twitter.svg'
import behance from '../../assets/img/social-behance.svg'
import enovatic from '../../assets/img/footer-enovatic.svg'

const Footer = () => {
  return (
    <footer 
        id='footer' 
        className="content_section" 
      >
        <div className="content_section_inner">
            <div className='social'>
                <a href="https://facebook.com" className='social-item'>
                    <img src={meta} alt="meta" />
                </a>
                <a href="https://twitter.com" className='social-item'>
                    <img src={twitter} alt="twitter" />
                </a>
                <a href="https://pinterest.com" className='social-item'>
                    <img src={pinterest} alt="pinterest" />
                </a>
                <a href="https://dribble.com" className='social-item'>
                    <img src={dribble} alt="dribble" />
                </a>
                <a href="https://behance.com" className='social-item'>
                    <img src={behance} alt="behance" />
                </a>
                <a href="https://github.com" className='social-item'>
                    <img src={github} alt="github" />
                </a>
            </div>
            <a href="https://enovatic.be" target="_blank" rel="noreferrer">
            <img src={enovatic} alt="Enovatic" />
            </a>
        </div>
    </footer>
  )
}

export default Footer