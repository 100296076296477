import React, { useState } from "react";
import "./Nav.css";
import hamburger from "../../assets/img/nav-hamburger.svg";
import { BiHome, BiSlider } from "react-icons/bi";
import { RiArticleLine, RiBook3Line } from "react-icons/ri";
import { MdWorkspacesOutline, MdOutlineWorkOutline } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

const Nav = () => {
	const [activeNav, setActiveNav] = useState("#");
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<nav className="navigation">
			<button href="#" onClick={toggle}>
				<img src={hamburger} alt="" className="hamburger" />
			</button>
			<ul className={isOpen ? "menu -active" : "menu "} onClick={toggle}>
				<li>
					<a
						href="#header"
						onClick={() => setActiveNav("#header")}
						className={activeNav === "#header" ? "active" : ""}
					>
						<div className="nav-icon">
							<BiHome />
						</div>
						<div className="nav-text">
							<p>Accueil</p>
						</div>
					</a>
				</li>

				<li>
					<a
						href="#about"
						onClick={() => setActiveNav("#about")}
						className={activeNav === "#about" ? "active" : ""}
					>
						<div className="nav-icon">
							<AiOutlineUser />
						</div>
						<div className="nav-text">
							<p>A propos</p>
						</div>
					</a>
				</li>

				<li>
					<a
						href="#services"
						onClick={() => setActiveNav("#services")}
						className={activeNav === "#services" ? "active" : ""}
					>
						<div className="nav-icon">
							<MdWorkspacesOutline />
						</div>
						<div className="nav-text">
							<p>Services</p>
						</div>
					</a>
				</li>

				<li>
					<a
						href="#experiences"
						onClick={() => setActiveNav("#experiences")}
						className={activeNav === "#experiences" ? "active" : ""}
					>
						<div className="nav-icon">
							<RiBook3Line />
						</div>
						<div className="nav-text">
							<p>Expériences</p>
						</div>
					</a>
				</li>

				<li>
					<a
						href="#competences"
						onClick={() => setActiveNav("#competences")}
						className={activeNav === "#competences" ? "active" : ""}
					>
						<div className="nav-icon">
							<BiSlider />
						</div>
						<div className="nav-text">
							<p>Compétences</p>
						</div>
					</a>
				</li>

				{/*<li>
            <a href="#realisations" onClick={() => setActiveNav('#realisations')} className={activeNav === '#realisations' ? 'active' : ''}>
              <div className='nav-icon'>
                <MdOutlineWorkOutline />
              </div>
              <div className="nav-text">
                <p>Réalisations</p>
              </div>
            </a>
          </li>*/}

				{/*<li>
            <a href="#blog" onClick={() => setActiveNav('#blog')} className={activeNav === '#blog' ? 'active' : ''}>
              <div className='nav-icon'>
                <RiArticleLine />
              </div>
              <div className="nav-text">
                <p>Blog</p>
              </div>
            </a>
          </li>*/}

				<li>
					<a
						href="#contact"
						onClick={() => setActiveNav("#contact")}
						className={activeNav === "#contact" ? "active" : ""}
					>
						<div className="nav-icon">
							<FiPhoneCall />
						</div>
						<div className="nav-text">
							<p>Contactez-moi</p>
						</div>
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Nav;
