import React from 'react'
import Aside from './components/0.core/Aside';
import Content from './components/0.core/Content';
import './assets/css/App.css';

const App = () => {
  return (
    <>
      <div id='top'></div>
      <div id='main'>
        <Aside />
        <Content />
      </div>
      
    </>
  )
} 

export default App
 