import React from 'react'
import './Services.css'
import serviceInfography from '../../assets/img/service-infography.svg'
import serviceDevelopment from '../../assets/img/service-development.svg'
import serviceDesign from '../../assets/img/service-design.svg'

const Services = () => {
  return (
    <section id='services' className="content_section">
      <div className="content_section_inner">
        <h2>Services</h2>
        <div className='container'>

          <div className='col col-1'>
            <div className='service-item'>
              <img src={serviceInfography} alt="" />
              <h4>Infographie</h4>
              <p>Une identité visuelle est importante pour l'image de marque ou produit. Je met toute mon expertise à la création et l’impression de celle-ci.</p>
            </div>
          </div>

          <div className='col col-1'>
          <div className='service-item'>
              <img src={serviceDevelopment} alt="" />
              <h4>Développement</h4>
              <p>Au même titre que l'infographie, le développement de sites et d'applications mobile sont très important pour une entreprise.</p>
            </div>
          </div>

          <div className='col col-1'>
          <div className='service-item'>
              <img src={serviceDesign} alt="" />
              <h4>UI/UX Design</h4>
              <p>L'élaboration d'experience utilisatrice efficace (UX) et la création d'interfaces web (UI) font partie de mes compétences phares.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
} 

export default Services