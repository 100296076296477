import React from 'react'
import './Experiences.css'

const Experiences = () => {
  
  return (
    <section id='experiences' className="content_section">
      <div className="content_section_inner">
        <h2>Expériences</h2>
        <div className='container'>
          <div className='col col-1-2'>
            <div className='experience-items-wrapper'>
              <div className='experience-items-line'></div>
              <div className='experience-items-inner-wrapper'>
                <div className="experience-item field">
                  <p className="date">2010-2011</p>
                  <h4>JL Gestion</h4>
                  <p>Dans ce centre de formation en informatique basé à Bruxelles, j'étais chargé des taches suivantes: Formations intra-entreprises, référencement naturel &amp; campagnes Adwords, création de sites web &amp; identité visuelle, infographie, serveur.</p>
                </div>
                <div className="experience-item field">
                  <p className="date">2011-2022</p>
                  <h4>Freelance</h4>
                  <p>J'ai ensuite dirigé ma carrière en tant que freelance et c'est ainsi que naquit Enovatic. Je propose principalement les services suivants:</p>
                  <p>Développement web frontend &amp; backend, applications, identité visuelle, infographie, formations en informatique, dépannage PC et hebergement web. &nbsp;</p>
                </div>

              </div>
            </div>
          </div>

          <div className='col col-1-2'>
            <div className='experience-items-wrapper'>
              <div className='experience-items-line'></div>
              <div className='experience-items-inner-wrapper'>
                <div className="experience-item training">
                  <p className="date">2009-2010</p>
                  <h4>Formation développeur web</h4>
                  <p>Certificat de développeur web décerné par le Centre de Formation 2000 à Bruxelles.</p>
                </div>
                <div className="experience-item training">
                  <p className="date">2014-2014</p>
                  <h4>Formation designer web</h4>
                  <p>Certificat de designer web décerné par le CEFAID à Bruxelles.</p>
                </div>
                <div className="experience-item training">
                  <p className="date">2014-2022</p>
                  <h4>Expériences autodidactes</h4>
                  <p>Afin de parfaire mes connaissances, je me suis autoformé aux différentes technologies modernes telles que: UI, UX, responsive design, React, Next, Node, GitHub, ...</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
} 

export default Experiences