import React from "react";
import "./Aside.css";
import logoFull from "../../assets/img/logo-full.svg";
import logoSmall from "../../assets/img/logo-small.svg";
import Nav from "./Nav";

function Aside() {
	return (
		<div id="aside" className="primary">
			<div className="aside-top">
				<a href="#header">
					<img src={logoFull} alt="meta" className="logo-full" />
				</a>
				<a href="#header">
					<img src={logoSmall} alt="meta" className="logo-small" />
				</a>
				<Nav />
			</div>
			<div className="aside-bottom">
				<small>&copy; 2024 Beckx Fabian.</small>
			</div>
		</div>
	);
}

export default Aside;
