import React from 'react'
import './Competences.css'

const Competences = () => {
  return (
    <section id='competences' className="content_section">
      <div className="content_section_inner">
        <h2>Compétences</h2>
        <div className='container'>
          <div className='col col-2-6'>
            <div className='competence-item'>
              <h4>Frontend</h4>
              <ul className='double'>
                <li>HTML 5</li>
                <li>CSS 3</li>
                <li>JavaScript</li>
                <li>JQuery</li>
                <li>React JS</li>
                <li>Gatsby</li>
                <li>DOM</li>
                <li>SASS</li>
                <li>Responsive</li>
                <li>Bootstrap</li>
                <li>SEO</li>
                <li>UI / UX</li>
                <li>Next JS</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>Applications</h4>
              <ul className='simple'>
                <li>React Native</li>
                <li>PWA</li>
                <li>Expo</li>
                <li>Flutter</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>Backend</h4>
              <ul className='simple'>
                <li>PHP</li>
                <li>Node JS</li>
                <li>Express</li>
                <li>Python</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>BDD</h4>
              <ul className='simple'>
                <li>MySQL</li>
                <li>Mongo DB</li>
                <li>SQ Lite</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>CMS</h4>
              <ul className='simple'>
                <li>WordPress</li>
                <li>Prestashop</li>
                <li>WooCommerce</li>
                <li>Drupal</li>
                <li>Django</li>
                <li>Elementor</li>
              </ul>
            </div>
          </div>
          <div className='col col-2-6'>
            <div className='competence-item'>
              <h4>Graphisme</h4>
              <ul className='double'>
                <li>Photoshop</li>
                <li>Illustrator</li>
                <li>InDesign</li>
                <li>Publisher</li>
                <li>Infinity Designer</li>
                <li>Sketch</li>
                <li>Figma</li>
                <li>XD</li>
                <li>Publisher</li>
                <li>Canvas</li>
                <li>Identité visuelle</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>Serveur</h4>
              <ul className='simple'>
                <li>FTP</li>
                <li>SSH</li>
                <li>PHPMyAdmin</li>
                <li>SSL</li>
                <li>DNS</li>
                <li>Emails</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>Déploiement</h4>
              <ul className='simple'>
                <li>Vercel</li>
                <li>Heroku</li>
                <li>Netlify</li>
              </ul>
            </div>
          </div>
          <div className='col col-1-6'>
            <div className='competence-item'>
              <h4>Divers</h4>
              <ul className='simple'>
                <li>Contentfull</li>
                <li>Strapi</li>
                <li>Make</li>
                <li>Zapier</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
} 

export default Competences