import React from 'react'
import './About.css'
import fabian from '../../assets/img/avatar-fabian-about.png'
import countProjets from '../../assets/img/count-projets.svg'
import countClients from '../../assets/img/count-clients.svg'
import countExperience from '../../assets/img/count-experience.svg'

const About = () => {
  return (
    <section id='about' className="content_section">
      <div className="content_section_inner">
        <h2>A propos</h2>
        <div className='container'>
          <div className='col col-1-3'>
            <img src={fabian} alt="" className='about-avatar' />
          </div>
          <div className='col col-2-3'>
            <h3>Qui suis-je ?</h3>
            <p>Bienvenue sur mon portfolio ! Je m’appelle Beckx Fabian, Développeur frontend, UI / UX designer et infographiste. J’accompagne les clients dans leur projet de la conception à la réalisation. De l’identité visuelle, logo et charte graphique, jusqu’à la conception de l’interface web je suis en mesure de gérer un projet web de A à Z.</p>
            <p>Passionné par les nouvelles technologies depuis mon plus jeune âge, j’ai décidé de poursuivre dans cette voie en pratiquant l’informatique dans ma vie professionnelle. Mon parcours m’a permis de développer des compétences dans des domaines variés de l’informatique mais c’est principalement dans le web que je me suis fait une vocation.</p>
            <p>Fort de ces expériences, j’ai pu trouver le développement web, une opportunité de carrière. Relever des défis dans ce domaine et répondre aux enjeux offerts par les innovations technologiques, tel est mon objectif dans le monde professionnel.</p>

          </div>

          <div className='col col-1'>
            <div className='count-item'>
              <img src={countProjets} alt="" />
              <div className='count-item-inner'>
                <h3>147</h3>
                <p>Projets completés</p>
              </div>
            </div>
          </div>

          <div className='col col-1'>
          <div className='count-item'>
              <img src={countExperience} alt="" />
              <div className='count-item-inner'>
                <h3>16</h3>
                <p>Années d'expériences</p>
              </div>
            </div>
          </div>

          <div className='col col-1'>
          <div className='count-item'>
              <img src={countClients} alt="" />
              <div className='count-item-inner'>
                <h3>27</h3>
                <p>Clients saftisfaits</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
} 

export default About