import React from 'react'
import './Header.css'
import avatar from '../../assets/img/avatar-fabian-header.png'
import scrollAbout from '../../assets/img/header-scroll.svg'
import meta from '../../assets/img/social-meta.svg'
import dribble from '../../assets/img/social-dribble.svg'
import pinterest from '../../assets/img/social-pinterest.svg'
import github from '../../assets/img/social-github.svg'
import twitter from '../../assets/img/social-twitter.svg'
import behance from '../../assets/img/social-behance.svg'

const Header = () => {
  return (
    <header id='header' className="content_section">
        <div className='header-top'></div>
        <div className='header-center'>
            <img src={avatar} alt="Fabian Beckx" className='avatar' />
            <h1 className='title'>Beckx Fabian</h1>
            <h4 className='subtitle'>Frontend Developer</h4>
            <div className='social'>
                <a href="https://facebook.com" className='social-item'>
                    <img src={meta} alt="meta" />
                </a>
                <a href="https://twitter.com" className='social-item'>
                    <img src={twitter} alt="twitter" />
                </a>
                <a href="https://pinterest.com" className='social-item'>
                    <img src={pinterest} alt="pinterest" />
                </a>
                <a href="https://dribble.com" className='social-item'>
                    <img src={dribble} alt="dribble" />
                </a>
                <a href="https://behance.com" className='social-item'>
                    <img src={behance} alt="behance" />
                </a>
                <a href="https://github.com" className='social-item'>
                    <img src={github} alt="github" />
                </a>
            </div>
            <a href="#contact" className='calltoaction'>Contactez-moi</a>
        </div>
        <div className='header-bottom'>
            <a href="#about">
            <img src={scrollAbout} alt="Fabian Beckx" />
            </a>
        </div>
    </header>
  )
}

export default Header